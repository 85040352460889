//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default {
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedFile: null,
      audioFile: null,
      isRecording: false,
      sendingError: null,
      sendingMessage: false,
      sendingFile: false,
      loading: false,
      organization_id: null,
      newMessage: "",
      audioPlaying: false,
      isModalOpen: false,
      selectedMessageId: null,
    };
  },
  computed: {
    ...mapGetters("chat", {
      nameForConversation: "nameForConversation",
      firstParticipant: "firstParticipant",
      conversationMessages: "conversationMessages",
      messageTimestamp: "messageTimestamp",
      messageBody: "messageBody",
      resourceUrl: "resourceUrl",
      messageSize: "messageSize",
    }),
    messages() {
      const messages = this.conversationMessages(this.conversation.id);

      this.scrollToBottom();
      return messages;
    },
  },
  async mounted() {
    this.loading = true;
    this.organization_id = localStorage.getItem("organizationId");
    await this.$store.dispatch("chat/fetchMessages", {
      organization_id: this.organization_id,
      conversation_id: this.conversation.id,
      deleted: true,
    });
    this.loading = false;

    await this.scrollToBottom();
  },
  methods: {
    formatTimestamp(timestamp) {
      return moment(timestamp).format("LT");
    },
    formatDate(timestamp) {
      return moment(timestamp).format("MM/DD/YYYY");
    },
    isFirstMessageOfDay(message, index) {
      if (!this.messages.length) return false;
      if (index === 0) return true;
      const currentDate = moment(message.created_at).startOf("day");
      const previousMessageDate = moment(
        this.messages[index - 1].created_at
      ).startOf("day");
      console.log(!currentDate.isSame(previousMessageDate));
      return !currentDate.isSame(previousMessageDate);
    },
    getMessagePositionClass(message, index) {
      // Ensure `message` is valid
      if (!message || !message.sender || !message.created_at) {
        return "invalid-message";
      }

      const previousMessage = this.messages[index - 1] || null;
      const nextMessage = this.messages[index + 1] || null;

      const currentDate = moment(message.created_at).startOf("day");
      const previousDate = previousMessage?.created_at
        ? moment(previousMessage.created_at).startOf("day")
        : null;
      const nextDate = nextMessage?.created_at
        ? moment(nextMessage.created_at).startOf("day")
        : null;
      if (message.type === "system") return "single-bubble";
      const isFirstInGroup =
        !previousMessage ||
        previousMessage.sender?.id !== message.sender.id ||
        previousMessage.type === "system" ||
        !currentDate.isSame(previousDate);

      const isLastInGroup =
        !nextMessage ||
        nextMessage.sender?.id !== message.sender.id ||
        nextMessage.type === "system" ||
        !currentDate.isSame(nextDate);

      if (message.type === "system" || (isFirstInGroup && isLastInGroup)) {
        return "single-bubble";
      } else if (isFirstInGroup) {
        return message?.sender?.id === this.$auth.user.id
          ? "first-sender-bubble"
          : "first-receiver-bubble";
      } else if (isLastInGroup) {
        return message?.sender?.id === this.$auth.user.id
          ? "last-sender-bubble"
          : "last-receiver-bubble";
      } else {
        return message?.sender?.id === this.$auth.user.id
          ? "middle-sender-bubble"
          : "middle-receiver-bubble";
      }
    },
    systemTypeMessage(message) {
      if (JSON.parse(message.body).action_type === "left") {
        console.log("LEFTTTTTTT");
        return "left_chat";
      }
      if (JSON.parse(message.body).action_type === "joined") {
        return "joined_message";
      }
      return "";
    },
    isFirstMessageInGroup(message, index) {
      // Ensure `message` is valid
      if (!message || !message.sender || !message.created_at) {
        return false; // Treat invalid messages as not being the first in a group
      }

      if (index === 0 && message.type !== "system") return true;

      const previousMessage = this.messages[index - 1] || null;

      // If the current or previous message is of type "system", it's the start of a new group
      if (message.type === "system") {
        return false;
      }

      if (previousMessage.type === "system") return true;

      const currentDate = moment(message.created_at).startOf("day");
      const previousDate = previousMessage?.created_at
        ? moment(previousMessage.created_at).startOf("day")
        : null;

      // Check if sender has changed or if the date is different
      return (
        !previousMessage ||
        previousMessage.sender?.id !== message.sender.id ||
        !currentDate.isSame(previousDate)
      );
    },
    messageContent(message) {
      return this.messageBody(message, this);
    },
    getRouteLink() {
      if (this.$route.name.includes("civiclee")) {
        return {
          name: "civiclee-users-id-overview",
          params: { id: this.firstParticipant(this.conversation).id },
        };
      } else if (this.$vianovaConfig.slug === "dental-ai") {
        return {
          name: "users-id-dental-overview",
          params: { id: this.firstParticipant(this.conversation).id },
        };
      } else {
        return {
          name: "users-id-overview-index-vitals",
          params: { id: this.firstParticipant(this.conversation).id },
        };
      }
    },
    openModal(message) {
      this.$emit(
        "open-delete-modal",
        this.conversation.id,
        this.organization_id,
        message.id
      );
    },
    formatSender(sender) {
      if (sender && sender.deleted_at) {
        const getInitials = (name) =>
          name
            .split(" ")
            .map((n) => n[0].toUpperCase())
            .join("");
        return `${getInitials(sender.display_name)} (deleted user)`;
      } else {
        return sender ? sender.display_name : "";
      }
    },
    handleModalClose() {},
    determineOrientation(event, message) {
      const media = event.target;
      let isLandscape = null;
      media.classList.remove("full-width", "small-width");
      if (media.naturalWidth) {
        isLandscape = media.naturalWidth > media.naturalHeight;
      } else {
        isLandscape = media.videoWidth > media.videoHeight;
      }
      if (isLandscape) {
        media.classList.add("full-width");
      } else {
        media.classList.add("small-width");
      }
    },
    openPreview(message) {
      if (message.type === "image") {
        this.$emit("open-image-preview", this.resourceUrl(message));
      }
    },
    handleScroll(event) {
      const { deltaY } = event;
      const chatContainer = event.currentTarget;
      const atTop = chatContainer.scrollTop === 0;
      const scrollPosition =
        chatContainer.scrollTop + chatContainer.clientHeight;
      const nearBottom = chatContainer.scrollHeight - scrollPosition < 1; // Tolerance of 1 pixel

      if ((deltaY < 0 && atTop) || (deltaY > 0 && nearBottom)) {
        event.preventDefault();
      }
    },
    updateMessageState() {
      this.$store.dispatch("chat/updateMessageState", {
        chatId: this.conversation.id,
        newText: this.newMessage,
        newAudio: this.audioFile,
        newFile: this.selectedFile,
      });
    },
    scrollToBottom() {
      setTimeout(() => {
        this.$refs.chatBody.scrollTop = this.$refs.chatBody.scrollHeight;
      }, 200);
    },
    removeSelectedFile() {
      this.selectedFile = null;
    },
    removeVideoFile() {
      this.audioFile = null;
    },
    fileUpload(isFile) {
      let input = document.createElement("input");
      input.type = "file";

      if (isFile) {
        input.accept = ".pdf,.doc,.docx"; // Allowed document extensions
      } else {
        input.accept = "image/*,video/mp4"; // Allowed image and video(mp4) extensions
      }

      input.onchange = (e) => {
        let file = e.target.files[0];
        if (file) {
          this.selectedFile = file;
          this.updateMessageState();
        }
        input.remove();
      };

      input.onblur = () => {
        input.remove();
      };

      input.click();
    },
    playAudio() {
      this.$refs.player.play();
    },
    pauseAudio() {
      this.$refs.player.pause();
    },

    async onAudio(event) {
      this.audioFile = event;
      this.isRecording = false;
      this.updateMessageState();
    },
    startPhoneCall(conversation) {
      const routeData = this.$router.resolve({
        name: "users-id-communications-index-phone-call",
        params: {
          id: this.firstParticipant(conversation).id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    startVideoCall(conversation) {
      const routeData = this.$router.resolve({
        name: "users-id-communications-index-video_call",
        params: {
          id: this.firstParticipant(conversation).id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    toggleMinimize() {
      this.$emit("toggleMinimize", { chatId: this.conversation.id }, true);
    },
    restoreState(state) {
      this.newMessage = state.text || "";
      this.audioFile = state.audio || null;
      this.selectedFile = state.file || null;
    },
    closeChat() {
      this.$emit("close", this.conversation.id);
    },
    async sendMessage() {
      if (this.sendingMessage) {
        console.log(`already sending message`);
        return;
      }

      this.sendingError = null;

      if (
        this.newMessage.trim() === "" &&
        !this.audioFile &&
        !this.selectedFile
      ) {
        console.log(`nothing to send...`);
        return;
      }
      this.sendingMessage = true;
      let payload = {
        message: {
          id: uuidv4(),
          timestamp: Date.now(),
          text: this.newMessage,
          type: "text",
        },
      };

      if (this.audioFile) {
        let formdata = new FormData();
        formdata.append(
          "file",
          this.audioFile.blob,
          `chat_audio_${this.conversation.id}_${this.audioFile.duration}.mp3`
        );
        await this.$axios
          .$post(this.$connect.FILE_UPLOAD_URL, formdata)
          .then((res) => {
            console.log(`chat_audio_${res.data}`);
            payload = {
              message: {
                meta: {
                  duration: this.parseDuration(this.audioFile.duration),
                  filename: `chat_audio_${res.data}mp3`,
                  filesize: +parseFloat(
                    this.audioFile.blob.size / 100000
                  ).toFixed(2),
                  filetype: this.audioFile.blob.type
                    .split("/")
                    .slice(-1)
                    .join(""),
                },
                timestamp: Date.now(),
                type: this.getType(this.audioFile.blob.type),
                url: res.data,
              },
            };
          })
          .catch((err) => {
            console.log(err);
            this.sendingMessage = false;
            this.sendingError = err;
            this.$store.dispatch("chat/getInbox", {
              organizationId: localStorage.getItem("organizationId"),
              currentPage: 1,
            });
            return;
          });
      }

      if (this.selectedFile) {
        this.sendingFile = true;
        let formdata = new FormData();
        formdata.append("file", this.selectedFile, this.selectedFile.name);

        await this.$axios
          .$post(this.$connect.FILE_UPLOAD_URL, formdata)
          .then((res) => {
            this.$store.dispatch("chat/getInbox", {
              organizationId: localStorage.getItem("organizationId"),
              currentPage: 1,
            });
            payload = {
              message: {
                meta: {
                  filename: this.selectedFile.name,
                  filesize: this.selectedFile.size,
                  filetype: this.selectedFile.type,
                },
                timestamp: Date.now(),
                type: this.getType(this.selectedFile.type),
                url: res.data,
              },
            };
          })
          .catch((err) => {
            console.log(err);
            this.sendingMessage = false;
            this.sendingError = err;
            return;
          });
      }

      try {
        this.sendingMessage = true;
        let result = await this.$axios.$post(
          `${this.organization_id}/chat/${this.conversation.id}`,
          payload
        );
        this.sendingMessage = false;
        if (!result) return;
        this.newMessage = "";
        this.audioFile = null;
        this.selectedFile = null;
        this.sendingFile = false;
        this.$store.dispatch("chat/fetchMessages", {
          organization_id: this.organization_id,
          conversation_id: this.conversation.id,
          deleted: true,
        });
        this.$store.dispatch("chat/getInbox", {
          organizationId: localStorage.getItem("organizationId"),
          currentPage: 1,
        });
        this.$nextTick(() => {
          this.$refs.newMessage && this.$refs.newMessage.focus();
        });
      } catch (e) {
        this.$nextTick(() => {
          this.$refs.newMessage && this.$refs.newMessage.focus();
        });
        this.sendingFile = false;
        this.sendingMessage = false;
        this.sendingError = e;
        this.showError(e);
      }
    },
    parseDuration(durationString) {
      if (!durationString) {
        return 0;
      }

      const durationParts = durationString.split(":").reverse();

      let duration = 0;
      const multipliers = [1, 60, 60 * 60, 60 * 60 * 24];
      for (const [index, item] of durationParts.entries()) {
        if (index >= multipliers.length) {
          break;
        }

        duration += +item * multipliers[index];
      }

      return duration * 1000;
    },
    getType(type) {
      return type.includes("image")
        ? "image"
        : type.includes("video")
        ? "video"
        : type.includes("audio")
        ? "audio"
        : "file";
    },
  },
};
