import sound from "../assets/chat-notification-vianova.mp3";
import moment from "moment-timezone";
import Vue from "vue";

export const state = () => ({
  conversations: [],
  patients: [],
  currentPatient: {},
  searchedPatients: [],
  allPages: 1,
  messages: {},
  pagination: {},
  unreadCount: null,
  minimizedChatInputStates: {},
  unreadCountForConversation: {},
  inbox_open: false,
});

export const mutations = {
  setMinimizeAll(state) {
    state.conversations = state.conversations.map((e) => {
      return { ...e, isMinimized: true };
    });
  },
  setCloseAll(state) {
    state.conversations = [];
  },
  setMessageDeleted(state, { conversation_id, message_id, deletedAt }) {
    console.log(`deleting message... ${message_id}`);
    const message = state.messages[conversation_id].data.find(
      (m) => m.id === message_id
    );
    if (message) {
      message.deleted_at = deletedAt;
    }
  },
  setMessages(state, { conversation_id, data }) {
    if (state.messages[conversation_id] == undefined) {
      const payload = {
        data: data.data.reverse(),
        pagination: data.pagination,
      };
      Vue.set(state.messages, conversation_id, payload);
    } else if (
      state.messages[conversation_id].pagination.current_page !=
      data.pagination.current_page
    ) {
      const payload = {
        data: data.data.reverse().concat(state.messages[conversation_id].data),
        pagination: data.pagination,
      };
      Vue.set(state.messages, conversation_id, payload);
    } else if (
      state.messages[conversation_id].pagination.current_page ==
      data.pagination.current_page
    ) {
      const payload = {
        data: data.data.reverse(),
        pagination: data.pagination,
      };
      Vue.set(state.messages, conversation_id, payload);
    }
  },
  setInboxOpen(state, data) {
    state.inbox_open = data;
  },
  closeChat(state, { chatId }) {
    state.conversations = state.conversations.filter((item) => {
      return item.id !== chatId;
    });
  },
  selectChat(state, { chatId }) {
    let chat = state.patients.find((item) => item.id === chatId) || {};
    const openChatsCount = state.conversations.filter(
      (conversation) => !conversation.isMinimized
    ).length;

    const chatInConversations = state.conversations.find(
      (conversation) => conversation.id === chat.id
    );
    let chatMaxLength = window.innerWidth <= 886 ? 1 : 2;
    if (!chatInConversations) {
      console.log(state.conversations);
      chat.isMinimized = false;
      if (openChatsCount >= chatMaxLength) {
        state.conversations = minimizeFirstOpenChat(state.conversations);
      }
      state.conversations.push(chat);
    } else {
      if (chatInConversations.isMinimized) {
        if (openChatsCount >= chatMaxLength) {
          state.conversations = minimizeFirstOpenChat(state.conversations);
        }
        state.conversations = unMinimizeChat(state.conversations, chatId);
      }
    }
  },

  toggleMinimizeConversation(state, { chatId, isMinimized }) {
    state.conversations = state.conversations.map((item) => {
      if (item.id === chatId) {
        item.isMinimized = isMinimized;
      }
      return item;
    });
  },
  clearUnreadCountForConversations(state) {
    state.unreadCountForConversation = {};
  },
  updateMessageState(state, { chatId, newText, newAudio, newFile }) {
    const conversation = state.conversations.find((c) => c.id === chatId);
    if (conversation) {
      let messageState = {
        text: newText,
        audio: newAudio,
        file: newFile,
      };
      Vue.set(state.minimizedChatInputStates, chatId, messageState);
    }
  },
  setUnreadCountForConversation(state, { conversation_id }) {
    if (state.unreadCountForConversation[conversation_id] === undefined) {
      console.log(`no badges for conversation... setting to 1`);
      Vue.set(state.unreadCountForConversation, conversation_id, 1);
    } else {
      console.log(
        `no badges for conversation... setting to ${
          state.unreadCountForConversation[conversation_id] + 1
        }`
      );
      Vue.set(
        state.unreadCountForConversation,
        conversation_id,
        state.unreadCountForConversation[conversation_id] + 1
      );

      console.log(state.unreadCountForConversation);
    }
    // unreadCountForConversation
  },
  setUnreadCount(state, value) {
    state.unreadCount = value;
  },
  SET_PATIENTS(state, data) {
    state.patients = data;
  },
  SET_PATIENT_CONVERSATION(state, data) {
    state.currentPatient = data;
  },
  SET_SEARCHED_PATIENTS(state, data) {
    state.searchedPatients = data;
  },
  SET_ALL_PAGES(state, data) {
    state.allPages = data;
  },
};

export const actions = {
  closeChat(state, { chatId }) {
    state.commit("closeChat", { chatId });
  },
  toggleMinimizeConversation({ commit, state }, payload) {
    commit("toggleMinimizeConversation", payload);
    // commit("updateMinimizedChatInputState", payload);
  },
  selectChat({ commit }, { chatId }) {
    commit("selectChat", { chatId });
  },
  updateMessageState({ commit }, payload) {
    commit("updateMessageState", payload);
  },
  async fetchMessages(
    { commit, dispatch },
    {
      organization_id,
      conversation_id,
      deleted = false,
      page = 1,
      per_page = 100,
    }
  ) {
    let data = await this.$axios.$get(
      `${organization_id}/chat/${conversation_id}?deleted=${deleted}&page=${page}&per_page=${per_page}`
    );
    commit("setMessages", { conversation_id, data });
    dispatch("getUnreadMessageCount");
    return data;
  },
  async deleteMessage(
    { commit, dispatch },
    { organization_id, conversation_id, message_id }
  ) {
    commit("setMessageDeleted", {
      conversation_id,
      message_id,
      deletedAt: new Date(),
    });
    return await this.$axios.$delete(
      `${organization_id}/chat/message/${message_id}`
    );
  },
  async deleteForAllMessage(
    { commit, dispatch },
    { organization_id, conversation_id, message_id }
  ) {
    commit("setMessageDeleted", {
      conversation_id,
      message_id,
      deletedAt: new Date(),
    });
    return await this.$axios.$delete(
      `${organization_id}/chat/${conversation_id}/message/${message_id}`
    );
  },
  async getInbox(
    { state, commit },
    { organizationId, currentPage, perPage = 20 }
  ) {
    let result = await this.$axios.$get(
      `${organizationId}/chat/inbox?page=${currentPage}&per_page=${perPage}`
    );
    let allPatientsArray = result.data;
    commit("SET_ALL_PAGES", result.pagination.last_page);
    commit(
      "SET_PATIENTS",
      allPatientsArray.filter(
        (value, index, array) =>
          array.map((e) => e.id).indexOf(value.id) === index
      )
    );
    return result;
  },
  async receivedNewMessageNotification(
    { commit, state, dispatch },
    { message }
  ) {
    // console.log(`received new message notification...`, message);
    if (
      state.conversations.find(
        (conversation) =>
          conversation.id === message.conversation_id &&
          conversation.isMinimized
      )
    ) {
      console.log(
        `updating badge for minimezed conversation... ${message.conversation_id}`
      );
      commit("setUnreadCountForConversation", {
        conversation_id: message.conversation_id,
      });
    } else if (
      state.conversations.find(
        (conversation) => conversation.id === message.conversation_id
      ) === undefined
    ) {
      console.log(`updating general messages badge...`);
      commit("setUnreadCount", state.unreadCount ? state.unreadCount + 1 : 1);
    }
    console.log("current converstation state is", state.messages);
    if (state.messages[message.conversation_id] != undefined) {
      if (
        state.conversations.find(
          (conversation) =>
            conversation.id === message.conversation_id &&
            !conversation.isMinimized
        )
      ) {
        console.log(
          `fetching messages for conversation... ${message.conversation_id}`
        );
        dispatch("fetchMessages", {
          organization_id: localStorage.getItem("organizationId"),
          conversation_id: message.conversation_id,
        });
      }
    }
    // if (!window.location.pathname.includes("/chats/")) {
    var audio = new Audio(sound);
    audio.play();
    // }
  },
  async getUnreadMessageCount({ commit, state }) {
    let organizationId = localStorage.getItem("organizationId");
    commit("clearUnreadCountForConversations");
    return this.$axios.$get(`${organizationId}/chat/unread`).then((res) => {
      if (res.data.unread_count == 0) {
        commit("setUnreadCount", null);
      } else {
        if (state.unreadCount < res.data.unread_count) {
          if (!window.location.pathname.includes("/chats/")) {
            var audio = new Audio(sound);
            audio.play();
          }
        }
        commit("setUnreadCount", res.data.unread_count);
      }
    });
  },
};
export const getters = {
  messageAction: () => (message) => {},

  resourceUrl: () => (message) => {
    const body = JSON.parse(message.body);
    return `https://vianova.s3.amazonaws.com/${body.url}`;
  },
  messageObject: () => (message) => {
    return JSON.parse(message.body);
  },
  messageSize: () => (message) => {
    return "123 kb";
  },
  messageBody: () => (message, app) => {
    if (message.deleted_at) {
      if (message.is_sender) {
        return app.$t("chat.message_deleted_by_sender");
      }
      return app.$t("chat.message_deleted");
    }
    const body = JSON.parse(message.body);
    if (
      message.type === "text" ||
      message.type === "link" ||
      message.type === "phone" ||
      message.type === "system"
    ) {
      return convertUrlsToLinks(body.text);
    } else if (message.type === "file") {
      return `${body.meta.filename}`;
    } else if (message.type === "image") {
    } else if (message.type === "audio") {
    } else {
      return `Type: ${message.type}`;
    }
    // audio
    // image;
  },
  conversationPagination: (state) => (conversation_id) => {
    if (state.messages[conversation_id] == undefined) {
      return {
        current_page: 1,
        item_per_page: 100,
        last_page: 1,
        total: 0,
      };
    }
    return state.messages[conversation_id].pagination;
  },

  conversationMessages: (state) => (conversation_id) => {
    if (state.messages[conversation_id] === undefined) {
      return [];
    }
    return state.messages[conversation_id].data;
  },
  initialsForConversation: (state) => (conversation, auth) => {
    if (conversation.direct_message) {
      let participants = [];
      conversation.conversation.participants.forEach((participant) => {
        if (participant.messageable_type === "App\\Models\\Patients\\Patient") {
          if (participant.user) {
            participants.push(participant.user);
          }
        }
      });
      let participant = participants[0];
      return getInitials(`${participant.display_name || ""}`);
    } else {
      let data = JSON.parse(conversation.data).name;
      return getInitials(`${data}`);
    }
  },
  lastMessage: () => (conversation, defaultMessage, app) => {
    if (conversation.conversation.last_message) {
      const lastMessage = JSON.parse(
        conversation.conversation.last_message.body
      );
      let content;

      console.log(lastMessage);
      if (lastMessage?.text) {
        content = lastMessage.text;
      }
      if (lastMessage?.type && ["audio", "AUDIO"].includes(lastMessage.type)) {
        content = "New audio message";
      }
      if (lastMessage?.type && ["video", "VIDEO"].includes(lastMessage.type)) {
        content = "New video message";
      }
      if (lastMessage?.type && ["image", "IMAGE"].includes(lastMessage.type)) {
        content = "New image message";
      }
      if (lastMessage.type == "file") {
        content = `${lastMessage.meta.filename}`;
      }
      return truncate(content, 30);
    }

    return defaultMessage;
  },
  messageTimestamp: () => (message, locale) => {
    return moment(message.unix_timestamp).locale(locale).format("L LT");
  },
  lastMessageTime: () => (conversation, locale) => {
    if (conversation.conversation.last_message) {
      return moment(conversation.conversation.last_message.unix_timestamp)
        .locale(locale)
        .fromNow(true);
    }
    return ``;
  },
  firstParticipant: () => (conversation) => {
    let participants = [];

    conversation.conversation.participants.forEach((participant) => {
      if (participant.messageable_type === "App\\Models\\Patients\\Patient") {
        if (participant.user) {
          participants.push(participant.user);
        }
      }
    });
    return participants[0];
  },
  nameForConversation: (state) => (conversation, auth) => {
    let title = "";
    if (conversation.direct_message) {
      let participants = [];
      conversation.conversation.participants.forEach((participant) => {
        if (participant.user && participant.user.id !== auth.user.id) {
          participants.push(participant.user.display_name || "");
        }
      });
      title = participants.join(", ");
    } else {
      title = JSON.parse(conversation.data).name;
    }
    return truncate(title, 20);
  },
  getInbox: (state) => (app) => {
    return state.patients.filter((conversation) => {
      return conversation.conversation.participants.some((participant) => {
        return (
          participant.user !== null && participant.user.id !== app.$auth.user.id
        );
      });
    });
  },
  getUnreadCountForConversation: (state) => (conversation) => {
    if (state.unreadCountForConversation[conversation.id] == undefined) {
      return null;
    }
    return state.unreadCountForConversation[conversation.id];
  },
  getUnreadCount(state) {
    return state.unreadCount;
  },
};

// Function to minimize an open chat if there are 2 or more open
function minimizeFirstOpenChat(conversations) {
  const firstOpenChat = conversations.find(
    (conversation) => !conversation.isMinimized
  );
  return conversations.map((conversation) => ({
    ...conversation,
    isMinimized:
      conversation.id === firstOpenChat?.id ? true : conversation.isMinimized,
  }));
}

// Function to un-minimize the selected chat
function unMinimizeChat(conversations, chatId) {
  const openChats = conversations.filter(
    (conversation) => !conversation.isMinimized
  );
  if (openChats.length >= 2) {
    const firstOpenChatIndex = conversations.findIndex(
      (conversation) => conversation.id === openChats[0].id
    );
    conversations[firstOpenChatIndex].isMinimized = true;
  }

  const chatIndex = conversations.findIndex(
    (conversation) => conversation.id === chatId
  );
  let chatToUnminimize;
  if (chatIndex !== -1) {
    chatToUnminimize = { ...conversations[chatIndex], isMinimized: false };
    conversations.splice(chatIndex, 1);
  } else {
    chatToUnminimize = { id: chatId, isMinimized: false };
  }

  conversations.push(chatToUnminimize);

  return conversations;
}

function truncate(str, n) {
  if (str == null) {
    return "";
  }
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

function getInitials(name) {
  return name[0].toUpperCase(); // Return the initials in uppercase
}

function convertUrlsToLinks(text) {
  const urlPattern =
    /(\b(https?|ftp|file):\/\/|\bwww\.)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]/gi;
  return text
    ? text.replace(urlPattern, (url) => {
        const href = url.match(/^www\./) ? `http://${url}` : url;
        return `<a href="${href}" target="_blank">${url}</a>`;
      })
    : "";
}
