var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"minimized-items-container"},[_c('div',[(_vm.conversations.length)?_c('div',{staticClass:"minimized-chat-button action-button"},[_c('b-dropdown',{attrs:{"no-caret":"","toggle-class":"rounded-circle px-2","variant":"none","dropleft":"","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{staticClass:"no-highlight",attrs:{"scale":"1","icon":"three-dots","aria-hidden":"true"}})]},proxy:true}],null,false,4225143753)},[_vm._v(" "),_c('b-dropdown-item',{on:{"click":_vm.closeAll}},[_vm._v("Close all chats")]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":_vm.minimizeAllChats}},[_vm._v("Minimize open chats")])],1)],1):_vm._e(),_vm._v(" "),_vm._l((_vm.conversations),function(conversation){return _c('div',{key:conversation.id,class:{
        'minimized-chat-button': true,
        'group-bubble': !conversation.direct_message,
        'direct-bubble': conversation.direct_message,
      },on:{"mouseover":function($event){return _vm.showMore(conversation.id, true)},"mouseleave":function($event){return _vm.showMore(null)}}},[(_vm.showMoreOptions[conversation.id])?_c('button',{class:{
          'bubble-actions': true,
          'group-bubble': !conversation.direct_message,
        },on:{"click":function($event){return _vm.$emit('close', conversation.id)}}},[_c('i',{staticClass:"uil-times"})]):_vm._e(),_vm._v(" "),(
          !_vm.showMoreOptions[conversation.id] &&
          _vm.getUnreadCountForConversation(conversation)
        )?_c('button',{class:{
          bubble: true,
          'group-bubble': !conversation.direct_message,
        }},[_vm._v("\n        "+_vm._s(_vm.getUnreadCountForConversation(conversation) > 100
            ? "99+"
            : _vm.getUnreadCountForConversation(conversation))+"\n      ")]):_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.hover",modifiers:{"left":true,"hover":true}}],attrs:{"title":_vm.nameForConversation(conversation, _vm.$auth)},on:{"click":function($event){return _vm.selectChat(conversation)}}},[(conversation.direct_message)?_c('span',[_vm._v(_vm._s(_vm.initialsForConversation(conversation)))]):_c('b-icon',{attrs:{"icon":"people-fill","variant":"white","scale":"1.3"}})],1)])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }